html, body {
  background-color: #F9F9F9;
  font-family: 'Share Tech Mono', monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  color: #333;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex-grow: 1;
}

* {
  font-family: 'Share Tech Mono', monospace;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.leaflet-container {
  width: 100wh; 
  height: 100vh;
}
